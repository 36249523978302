import React from "react";
import { Link } from "react-router-dom";
import {
  FaEye,
  FaGavel,
  FaCode,
  FaSearch,
  FaRocket,
  FaChalkboardTeacher,
  FaBuilding,
  FaOilCan,
  FaStore,
  FaShoppingCart,
  FaHeartbeat,
  FaIndustry,
} from "react-icons/fa";
import ailg from "../components/img/star.svg";
import mobile3 from "../components/img/mobile3.png";
import video1 from "../components/img/video/video2.mp4"; 
import ContactIgn from "../components/ContactIgn";
import BookNow from "../components/BookNow";


const Contact = () => {
  return (
    <>

 

      <div className="ptii2 bgwhyign1">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <div className="text-white d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>Contact US</strong>
                </span>
              </div>

              <h1 className="font2 font3 qaw text-white text-start mb-5">
              Get in  <span className="aitxt">Touch with Us! </span> 
              </h1>
            </div>

            <div className="col-md-6">
              <div className="font4 abt1 text-white">
              We’d love to hear from you! Whether you have a question, need support, or want to discuss your next big project, our team is here to help.
              </div>
            </div>
          </div>
        </div>
      </div>

    

      <div className="bgwhyign1 bgworldmap">
      <ContactIgn/>
      </div>


   
    </>
  );
};

export default Contact;
