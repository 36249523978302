import React from "react";
import { Link } from "react-router-dom";
import {
  FaClipboardList,
  FaDraftingCompass,
  FaCode,
  FaCheckCircle,
  FaRocket,
  FaChalkboardTeacher,
  FaBuilding,
  FaOilCan,
  FaStore,
  FaShoppingCart,
  FaHeartbeat,
  FaIndustry,
} from "react-icons/fa";
import ailg from "../../components/img/star.svg";
import backend3 from "../../components/img/backend3.gif";
import video1 from "../../components/img/video/video2.mp4";
import BookNow from "../../components/BookNow";
import Neuralink from "../../components/Neuralink";
import DevLang from "../../components/DevLang";

const BackendDevelopemnt = () => {
  return (
    <>
      <div className="bgbackend py-5">
        <div className="container conttop">
          <div className="row">
            <div className="col-md-6">
              <h1 className="font2 qaw ">Backend Development Services</h1>
              <p>
                We specialize in developing reliable, scalable, and secure
                server-side solutions for businesses. Whether it's on-premise
                systems, cloud-based environments, or serverless architectures,
                our team builds robust backend infrastructures that are designed
                for growth and high availability.
              </p>
              <Link to="/" className="hirebtn2 mb-4">
                Get Quote Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="contaner-fluid video-background"> */}
      <div className="container my-5 ">
        {/* <video autoPlay muted loop className="background-video">
    <source src={video1} type="video/mp4"/>
    Your browser does not support the video tag.
  </video> */}

        <div className="row content-overlay">
          <div className="col-md-8">
            <h1 className="font2 qaw">
              Comprehensive <span className="aitxt">Backend Development</span>{" "}
              Services
            </h1>
            <p>
              Backend development is the backbone of any successful web or
              mobile application. We specialize in creating robust, scalable,
              and secure backend solutions that seamlessly integrate with your
              front-end and support your business objectives. Whether you need a
              custom-built API, a cloud-based solution, or a high-performing
              server infrastructure, our backend development services are
              designed to ensure reliability, performance, and scalability at
              every stage of your application’s lifecycle.
            </p>

            <p>
              Our team leverages modern frameworks, technologies, and best
              practices to build systems that are efficient, secure, and
              optimized for performance. From database design and server
              architecture to API development and integration with third-party
              services, we ensure that the backend systems we build can scale as
              your business grows and adapt to future requirements.
            </p>
            <p>
              With a focus on seamless functionality and security, we make sure
              that your backend is not only efficient but also easy to maintain,
              upgrade, and extend. By delivering solutions that are fully
              integrated with your business processes, we empower your
              organization to drive innovation and remain competitive in the
              digital age.
            </p>
          </div>

          <div className="col-md-4">
            <img
              src={backend3}
              alt="Custom Software Solutions"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="bg-white py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>What options we have for you..</strong>
                </span>
              </div>

              <h1 className="font2 qaw text-dark text-start mb-5">
                Our <span className="aitxt">backend development </span>services
              </h1>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center process-flow">
                <div className="arrow">.01</div>
                <div className="process-step">
                  {/* <FaClipboardList className="process-icon" /> */}
                  <p>Custom backend development</p>
                  <div className="lap1">
                    Our developers will establish the core framework for your
                    web or mobile application, designing the system architecture
                    while providing you with full control throughout the
                    development process.
                  </div>
                </div>
                <div className="arrow">.02</div>
                <div className="process-step">
                  {/* <FaDraftingCompass className="process-icon" /> */}
                  <p>Enterprise backend solutions</p>
                  <div className="lap1">
                    We provide solutions focused on scalability, reliability,
                    and security for your enterprise, ranging from data
                    management to DevOps practices.
                  </div>
                </div>
                <div className="arrow">.03</div>
                <div className="process-step">
                  {/* <FaCode className="process-icon" /> */}
                  <p>Cloud backend solutions</p>
                  <div className="lap1">
                    We offer a comprehensive range of services to manage cloud
                    deployment, migration, and security, easing the burden on
                    your team.
                  </div>
                </div>
                <div className="arrow">.04</div>
                <div className="process-step">
                  {/* <FaCheckCircle className="process-icon" /> */}
                  <p>Backend API integration services</p>
                  <div className="lap1">
                    We ensure smooth and efficient integration of payment
                    gateways, social media platforms, and third-party services,
                    delivering a seamless experience for your business.
                  </div>
                </div>
                <div className="arrow">.05</div>
                <div className="process-step">
                  {/* <FaRocket className="process-icon" /> */}
                  <p>Backend testing</p>
                  <div className="lap1">
                    We carefully assess the server-side components of your
                    website, application, or system to ensure they align with
                    quality standards, performance expectations, and functional
                    requirements.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for industry */}

      <div className="bg-white py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>Your Technical Requirements</strong>
                </span>
              </div>

              <h1 className="font2 qaw text-dark text-start mb-5">
                No matter what your technical requirements are,{" "}
                <span className="aitxt">
                  we have the expertise to meet them.
                </span>
              </h1>

              {/* Define Linear Gradient */}
              <svg width="0" height="0">
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#1FB3FD", stopOpacity: 1 }}
                    />
                    <stop
                      offset="20%"
                      style={{ stopColor: "#8087F2", stopOpacity: 1 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: "#E957E7", stopOpacity: 1 }}
                    />
                    <stop
                      offset="77%"
                      style={{ stopColor: "#F359A5", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#FE5C5D", stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
              </svg>

              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaCode
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Code quality and architecture</div>
                    <p>
                      We guarantee that your systems are developed to the
                      highest standards by offering a comprehensive range of
                      services tailored to your needs, including code reviews,
                      architecture design, refactoring, optimization, and CI/CD.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaBuilding
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Security and data management</div>
                    <p>
                      You can trust that your backend infrastructure is
                      safeguarded against potential threats. We conduct
                      comprehensive security assessments and implement secure
                      authentication and encryption techniques to ensure robust
                      protection.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaOilCan
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Testing, deployment, maintenance</div>
                    <p>
                      We are dedicated to delivering solutions that extend
                      beyond building robust infrastructure. As such, your
                      backend solutions undergo thorough testing, seamless
                      deployment, and meticulous maintenance to ensure optimal
                      performance and reliability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DevLang />

      <div className="bg-light py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>FAQ's</strong>
                </span>
              </div>

              <h1 className="font2 font3 qaw text-dark text-start mb-5">
                Frequently Asked <span className="aitxt">Questions</span>
              </h1>
            </div>

            <div className="col-md-6">
              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq1"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq1" className="accordion-header text-dark">
                    What backend services does your company offer?
                  </label>
                  <div className="accordion-body">
                    We specialize in custom backend development, including
                    building server-side architectures, database management, API
                    integrations, cloud infrastructure setup, security
                    implementation, and DevOps practices. We focus on creating
                    scalable, secure, and high-performing backend systems
                    tailored to your business needs.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq2"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq2" className="accordion-header text-dark">
                    How do you ensure the scalability of the backend systems?
                  </label>
                  <div className="accordion-body">
                    Our team designs backend systems with scalability in mind.
                    We use cloud infrastructure, microservices architecture,
                    load balancing, and database optimization techniques to
                    ensure that the backend can handle growing traffic and data
                    needs.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq3"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq3" className="accordion-header text-dark">
                    What programming languages and technologies do you use for
                    backend development?
                  </label>
                  <div className="accordion-body">
                    We work with a variety of backend technologies, including
                    Node.js, Python, Ruby, Java, PHP, and C#. Our team chooses
                    the most suitable language and framework based on the
                    specific requirements of your project, whether it’s for
                    performance, security, or scalability.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq4"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq4" className="accordion-header text-dark">
                    What is the process for developing a custom backend for my
                    application?
                  </label>
                  <div className="accordion-body">
                    Our development process begins with gathering your project
                    requirements, followed by system design, architecture
                    planning, and technology selection. We then develop and
                    integrate the backend components, perform thorough testing,
                    and ensure the system is scalable, secure, and optimized
                    before deploying.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq5"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq5" className="accordion-header text-dark">
                    Do you offer cloud hosting and infrastructure management for
                    backend systems?
                  </label>
                  <div className="accordion-body">
                    Yes, we offer cloud-based solutions, including cloud
                    hosting, cloud migration, and infrastructure management. We
                    work with leading cloud providers like AWS, Azure, and
                    Google Cloud to set up secure and scalable environments for
                    your backend applications.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq6"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq6" className="accordion-header text-dark">
                    What are microservices, and do you offer microservices
                    architecture?
                  </label>
                  <div className="accordion-body">
                    Microservices are a design pattern that breaks down backend
                    systems into smaller, independent services that communicate
                    through APIs. We specialize in designing and implementing
                    microservices architectures for scalable and flexible
                    backend systems.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq7"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq7" className="accordion-header text-dark">
                    Do you offer backend system maintenance and support?
                  </label>
                  <div className="accordion-body">
                    Yes, we provide ongoing maintenance and support for your
                    backend systems. This includes troubleshooting, performance
                    monitoring, regular updates, and implementing new features
                    as your business evolves.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookNow />
    </>
  );
};

export default BackendDevelopemnt;
