import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import  './components/css/style.css'
import  './components/css/common.css'
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CustomSoftware from "./pages/service/CustomSoftware";
import ScrollToTop from "./components/ScrollToTop";
import BackendDevelopemnt from "./pages/service/BackendDevelopment";
import AiSoftwareDevelopemnt from "./pages/service/AiSoftwareDevelopment";
import MobileDevelopemnt from "./pages/service/MobileDevelopment";

import { logPageView } from './utils/analytics';

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null; // No UI rendering needed
};

const App = () => {
  return (
    <Router>
      <TrackPageView />
       <ScrollToTop />
      <Header />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/custom-software-development" element={<CustomSoftware />} />
          <Route path="/backend-development" element={<BackendDevelopemnt />} />
          <Route path="/ai-software-development" element={<AiSoftwareDevelopemnt />} />
          <Route path="/mobile-app-development" element={<MobileDevelopemnt />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
