import React from "react";
import { Link } from "react-router-dom";
import {
  FaUsers,
  FaLaptopCode,
  FaCode,
  FaRobot,
  FaMobileAlt,
  FaInfinity,
  FaClipboardList,
} from "react-icons/fa";
import ailg from "./img/star.svg";
import imgaa from "./img/imgaa.webp";
const OurService = () => {
  return (
    <div className="">
      <div className="container py-5">
        <div className="my-3">

        <div className="text-dark d-flex">
            <span>
              <span className="star-block d-inline-block">
                <img src={ailg} alt="" className="star1" />
                <img src={ailg} alt="" className="star2" />
                <img src={ailg} alt="" className="star3" />
              </span>
            </span>
            <span>
              <strong>Our Services</strong>
            </span>
          </div>
          <h1 className="text-dark qaw font2">
          Services we provide, but {" "}
            <span className="aitxt">not limited to...</span>
          </h1>
          
          {/* <h1 className="text-dark udrl qaw">
            Services we provide, but not limited to...
          </h1> */}
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Custom Software Development</div>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  <Link to="/custom-software-development" className="lrnmore ">Learn More</Link>
                </span>
                <span><FaLaptopCode className="info-icon2 " /></span>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Backend Development</div>
              <div className="text-end d-flex justify-content-between align-items-end">
              <span className="text-dark">
                  <Link to="/backend-development" className="lrnmore ">Learn More</Link>
                </span>
                <span><FaCode className="info-icon2 " /></span>
              </div> 
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">AI Software Development</div>
              <div className="text-end d-flex justify-content-between align-items-end">
              <span className="text-dark">
                  <Link to="/ai-software-development" className="lrnmore ">Learn More</Link>
                </span>
                <span><FaRobot className="info-icon2 " /></span>
              </div> 
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Mobile App Development</div>
              <div className="text-end d-flex justify-content-between align-items-end">
              <span className="text-dark">
                  <Link to="/mobile-app-development" className="lrnmore ">Learn More</Link>
                </span>
                <span><FaMobileAlt className="info-icon2 " /></span>
              </div>  
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">DevOps & Cloud Solutions</div>
              <div className="text-end d-flex justify-content-between align-items-end">
              <span className="text-dark">
                  <Link to="/" className="lrnmore ">Learn More</Link>
                </span>
                <span><FaInfinity className="info-icon2 " /></span>
              </div> 
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">BPM & CRM Systems</div>
              <div className="text-end d-flex justify-content-between align-items-end">
              <span className="text-dark">
                  <Link to="/" className="lrnmore ">Learn More</Link>
                </span>
                <span><FaClipboardList className="info-icon2 " /></span>
              </div>
               
            </div>
          </div>
        </div>

        <div className="bgconsult d-flex justify-content-between align-items-center mt-5">
          <div className="">
            <div className="conslt qaw font2">Book Your Free Consultation Today ..!!</div>
            <p>
              Book an appointment to explore how we can enhance your team's
              productivity and streamline your operations!
            </p>
            <Link to="/" className="hirebtn">
              Free Consultation
            </Link>
          </div>
          <div>
            <img src={imgaa} className="img-fluid l12" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurService;
