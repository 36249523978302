import React, { useEffect } from "react";
import anime from "animejs";
import Slider from "react-slick";
import hero from "../components/img/img1.png";
import hero2 from "../components/img/img2.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const HomeAnimation = () => {
  useEffect(() => {
    // Canvas animation with mouse interaction
    const canvas = document.getElementById("aiCanvas");
    const ctx = canvas.getContext("2d");

    const particles = [];
    const particleCount = 50;
    const colors = ["#00f2ff", "#ff6f61", "#28a745", "#007bff"]; // AI theme colors

    let mouseX = undefined;
    let mouseY = undefined;
    let isHovered = false;

    // Particle class definition
    class Particle {
      constructor(x, y, dx, dy, radius, color) {
        this.x = x;
        this.y = y;
        this.dx = dx;
        this.dy = dy;
        this.radius = radius;
        this.color = color;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        ctx.fillStyle = this.color;
        ctx.fill();
        ctx.closePath();
      }

      update() {
        if (isHovered && mouseX && mouseY) {
          // Move particle towards mouse if hover state is true
          const angle = Math.atan2(mouseY - this.y, mouseX - this.x);
          this.dx += Math.cos(angle) * 0.1;
          this.dy += Math.sin(angle) * 0.1;
        } else {
          // Random motion when not hovering
          if (this.x + this.radius > canvas.width || this.x - this.radius < 0) {
            this.dx = -this.dx;
          }
          if (this.y + this.radius > canvas.height || this.y - this.radius < 0) {
            this.dy = -this.dy;
          }
        }

        this.x += this.dx;
        this.y += this.dy;
        this.draw();
      }
    }

    // Initialize particles
    function init() {
      for (let i = 0; i < particleCount; i++) {
        const radius = Math.random() * 5 + 2;
        const x = Math.random() * (canvas.width - radius * 2) + radius;
        const y = Math.random() * (canvas.height - radius * 2) + radius;
        const dx = (Math.random() - 0.5) * 2;
        const dy = (Math.random() - 0.5) * 2;
        const color = colors[Math.floor(Math.random() * colors.length)];
        particles.push(new Particle(x, y, dx, dy, radius, color));
      }
    }

    // Connect particles visually
    function connectParticles() {
      for (let a = 0; a < particles.length; a++) {
        for (let b = a; b < particles.length; b++) {
          const distance = Math.sqrt(
            Math.pow(particles[a].x - particles[b].x, 2) +
              Math.pow(particles[a].y - particles[b].y, 2)
          );
          if (distance < 100) {
            ctx.beginPath();
            ctx.moveTo(particles[a].x, particles[a].y);
            ctx.lineTo(particles[b].x, particles[b].y);
            ctx.strokeStyle = "rgba(255,255,255,0.1)";
            ctx.lineWidth = 1;
            ctx.stroke();
            ctx.closePath();
          }
        }
      }
    }

    // Animation loop
    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle) => particle.update());
      connectParticles();
    }

    // Resize canvas when window size changes
    function resizeCanvas() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }

    // Mouse movement event listener
    function mouseMoveHandler(event) {
      mouseX = event.clientX;
      mouseY = event.clientY;
    }

    // Mouse hover event listener
    function mouseEnterHandler() {
      isHovered = true;
    }

    function mouseLeaveHandler() {
      isHovered = false;
    }

    // Set up event listeners
    window.addEventListener("resize", resizeCanvas);
    window.addEventListener("mousemove", mouseMoveHandler);
    canvas.addEventListener("mouseenter", mouseEnterHandler);
    canvas.addEventListener("mouseleave", mouseLeaveHandler);

    resizeCanvas();
    init();
    animate();

    return () => {
      // Clean up event listeners when the component is unmounted
      window.removeEventListener("resize", resizeCanvas);
      window.removeEventListener("mousemove", mouseMoveHandler);
      canvas.removeEventListener("mouseenter", mouseEnterHandler);
      canvas.removeEventListener("mouseleave", mouseLeaveHandler);
    };
  }, []);

  // Slider Settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Slide transitions every 2 seconds
    arrows: false,
  };

  return (
    <div className="home-container">
      <section className="hero-section position-relative">
        {/* AI Animated Background */}
        <canvas id="aiCanvas" className="position-absolute"></canvas>

        {/* Hero Slider */}
        <div className="container text-start position-relative">
          <Slider {...settings}>
            <div className="slider-item">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h1 className="text-dark qaw font2">
                    Empowering Your <span className="aitxt">Digital Transformation</span> 
                  </h1>
                  <p className="text-dark">
                    At <strong>IGN SOFTWARES</strong>, we provide innovative IT
                    solutions tailored to transform your business. From custom
                    software to enterprise-level systems, our expertise
                    streamlines operations, enhances productivity, and drives
                    growth.
                  </p>
                  <Link to="/" className="hirebtn2 mb-4">Get Started</Link>
                </div>
                <div className="col-md-6 hide-mobile">
                  <img src={hero2} alt="Hero" className="img-fluid" />
                </div>
              </div>
            </div>

            <div className="slider-item">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h1 className="text-dark qaw font2">
                    Transform Your <span className="aitxt">Business with Technology</span> 
                  </h1>
                  <p className="text-dark">
                    Experience seamless IT integration with IGN Software.
                    Future-proof your business operations with cutting-edge
                    technology solutions designed to scale and evolve.
                  </p>
                  <Link to="/" className="hirebtn2 mb-4">Get Started</Link>
                </div>
                <div className="col-md-6 hide-mobile">
                  <img src={hero} alt="Hero 2" className="img-fluid" />
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default HomeAnimation;
