import React, { useState } from "react";
import ailg from "./img/star.svg";

const Service = () => {
  // State to track the HTML content for the hovered block
  const [description, setDescription] = useState("");
  const [keypoint, setKeypoint] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track the index of hovered block
  
  const services = [
    {
      title: "Custom Software Development",
      content: `<h4>Custom Software Development</h4>
                <p>With custom software development from <strong>IGN SOFTWARES</strong>, 
                you can launch software products that revolutionize industries and diversify 
                your product portfolios. We help your product reach the right audience and 
                desired metrics, covering all stages of software development, from analyzing 
                requirements and systems analysis to development, testing, migration, and 
                deployment.</p>
                
                `,

        keypoint:`<div>Tailored to Your Specific Needs</div>
        <div>Scalability and Future-Proofing</div>
        <div>Improved Efficiency</div>
        <div>Enhanced Security</div> 
        <div>Competitive Advantage</div>
        <div>Seamless Integration</div>
        `
    },
    {
      title: "Backend Development",
      content: `<h4>Backend Development</h4>
                <p>Backend development is at the core of any robust and scalable web application. It involves the creation and maintenance of servers, databases, and application logic that work behind the scenes to ensure smooth, efficient, and secure operations. Backend developers focus on creating the infrastructure necessary to handle client requests, process them, and deliver the right responses.</p>`,
   
                keypoint:`<div>Tailored Server-Side Solutions</div>
                <div>Database Management</div>
                <div>API Development</div>
                <div>User Authentication & Authorization</div> 
                <div>Data Processing, Security</div>
                <div>Cloud Infrastructure</div>
                <div>Performance Optimization</div>
                <div>Microservices Architecture</div>
                <div>Version Control & Deployment</div>
                `

            },
    {
      title: "AI Software Development",
      content: `<h4>AI Software Development</h4>
                <p>AI software development focuses on creating applications and systems that use artificial intelligence to automate tasks, analyze data, and make intelligent decisions. AI-powered solutions have become integral to businesses across various industries, offering significant improvements in efficiency, decision-making, and user experience. From chatbots and virtual assistants to complex machine learning algorithms, AI development encompasses a wide range of technologies and techniques aimed at simulating human intelligence.</p>`,
                keypoint:`<div>Data-Driven Decision Making</div>
                <div>Machine Learning Models</div>
                <div>Natural Language Processing (NLP)</div>
                <div>Computer Vision & Deep Learning</div> 
                <div>Reinforcement Learning & Automation</div>
                <div>Model Training & Evaluation & AI Integration & Ethical AI</div> 
                `

            },
    {
      title: "Mobile App Development",
      content: `<h4>Mobile App Development</h4>
                <p>The mobile app development process typically involves several stages, including planning, design, development, testing, deployment, and ongoing maintenance. During the development phase, developers must focus on creating user-friendly interfaces, ensuring smooth performance, and integrating features that meet the needs of the target audience. Mobile app development also requires a strong understanding of mobile platforms, operating systems, and hardware capabilities, such as GPS, camera, and accelerometer, to take full advantage of the mobile environment.</p>`,
                keypoint:`<div>Native & Cross-Platform Apps</div>
                <div>User-Centered Design</div>
                <div>App Performance Optimization</div>
                <div>App Store Deployment & Offline Capabilities</div> 
                <div>Testing & Debugging & Integration with Backend Systems</div>
                <div>Maintenance & Updates & Analytics & User Insights</div> 
                `
            },
    {
      title: "DevOps & Cloud Solutions",
      content: `<h4>DevOps & Cloud Solutions</h4>
                <p>The integration of DevOps practices with cloud infrastructure creates a powerful synergy, enabling organizations to streamline their software development and deployment processes. Cloud platforms provide the tools and services needed for DevOps automation, such as container orchestration (e.g., Kubernetes), continuous integration/continuous deployment (CI/CD) pipelines, monitoring, and cloud-native applications.</p>`,
                keypoint:`<div>Continuous Integration and Continuous Delivery</div>
                <div>Infrastructure as Code (IaC)</div>
                <div>Cloud-Native Architecture & Automation & Containerization</div>
                <div>Monitoring and Logging & Scalability and Flexibility</div> 
                <div>Security and Compliance & Cost Efficiency</div>
                <div>Collaboration and Communication</div> 
                <div>High Availability and Performance</div> 
                `
            },
    {
      title: "ERP & CRM Systems",
      content: `<h4>ERP & CRM Systems</h4>
                <p>Enterprise Resource Planning (ERP) and Customer Relationship Management (CRM) systems are critical tools for organizations looking to streamline their business processes, improve efficiency, and enhance customer relationships. These systems help businesses manage their resources, operations, and customer interactions in a unified manner, leading to improved decision-making and overall business performance.</p>`,
                
                keypoint:`<div>End-to-End Integration</div>
                <div>Automation of Core Business Processes</div>
                <div>Real-Time Reporting and Analytics</div>
                <div>Centralized Data Management</div> 
                <div>Customization and Scalability</div>
                <div>Supply Chain Management & Human Resource Management</div> 
                <div>360-Degree Customer View</div> 
                <div>Lead and Opportunity Management</div> 
                <div>Customer Segmentation</div> 
                <div>Automation of Sales and Marketing Tasks</div> 
                <div>Integration with Third-Party Tools</div> 
                <div>Customer Service Management</div> 
                `

            },
  ];

  return (
    <div>
      <div className="container py-5">
        <div className="my-3">
          <div className="text-dark d-flex">
            <span>
              <span className="star-block d-inline-block">
                <img src={ailg} alt="" className="star1" />
                <img src={ailg} alt="" className="star2" />
                <img src={ailg} alt="" className="star3" />
              </span>
            </span>
            <span>
              <strong>Our Services</strong>
            </span>
          </div>

          <h1 className="text-dark qaw font2">
            Reinvent business operations with our{" "}
            <span className="aitxt">revolutionary services</span>
          </h1>
        </div>
        <div className="row">
          {/* Left column with titles */}
          <div className="col-md-6">
            {services.map((service, index) => (
              <div
                key={index}
                onMouseEnter={() => {
                  setDescription(service.content);
                  setKeypoint(service.keypoint);
                  setHoveredIndex(index);
                }}
                onMouseLeave={() => {
                  setHoveredIndex(null);
                }}
              >
                <h3 className="text-dark">{service.title}</h3>
                <p className="text-dark">Learn more about {service.title}</p>
                <hr
                  className={
                    hoveredIndex === index ? "custom-hr" : "custom-hr2"
                  }
                />
              </div>
            ))}
          </div>

          {/* Right column with description */}
          <div className="col-md-6 clos p-3 bgservice">
            <div className="clos2">
              <div
                className="text-dark blck mb-3"
                dangerouslySetInnerHTML={{
                  __html:
                    description ||
                    `<h4>Custom Software Development</h4>
                <p>With custom software development from Timspark, you can launch software products that revolutionize industries and diversify your product portfolios. We help your product reach the right audience and desired metrics, covering all stages of software development, from analyzing requirements and systems analysis to development, testing, migration, and deployment.</p>
                
                
                
                <div>Tailored to Your Specific Needs</div>
        <div>Scalability and Future-Proofing</div>
        <div>Improved Efficiency</div>
        <div>Enhanced Security</div> 
        <div>Competitive Advantage</div>
        <div>Seamless Integration</div>
        
                `,
               
            
            }}
              ></div>

<div
                className="text-dark blck "
                dangerouslySetInnerHTML={{
                  __html:
                    keypoint ||
                    ``,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
