import React from "react";
import { Link } from "react-router-dom";
import {
  FaIndustry,
  FaRobot,
  FaMobileAlt,
  FaCloud,
  FaBuilding,
  FaCar,
  FaSearch,
  FaHospital,
} from "react-icons/fa"; // Added industry-specific icons
import ailg from "./img/star.svg";
import imgaa from "./img/imgaa.webp";

const Industry = () => {
  return (
    <div className="">
      <div className="container py-5">
        <div className="my-3">
          <div className="text-dark d-flex">
            <span>
              <span className="star-block d-inline-block">
                <img src={ailg} alt="" className="star1" />
                <img src={ailg} alt="" className="star2" />
                <img src={ailg} alt="" className="star3" />
              </span>
            </span>
            <span>
              <strong>Industries We Cater To</strong>
            </span>
          </div>
          <h1 className="text-dark qaw font2">
            Industries we cater to, but{" "}
            <span className="aitxt"> not limited to...</span>
          </h1>
        </div>

        {/* Define Linear Gradient */}
        <svg width="0" height="0">
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{ stopColor: "#1FB3FD", stopOpacity: 1 }} />
              <stop offset="20%" style={{ stopColor: "#8087F2", stopOpacity: 1 }} />
              <stop offset="50%" style={{ stopColor: "#E957E7", stopOpacity: 1 }} />
              <stop offset="77%" style={{ stopColor: "#F359A5", stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: "#FE5C5D", stopOpacity: 1 }} />
            </linearGradient>
          </defs>
        </svg>

        <div className="row">
          {/* Industry 1 - Manufacturing */}
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Manufacturing</div>
              <p>Automate inventory and order management to streamline the overall business processes</p>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  {/* <Link to="/" className="lrnmore">
                    Learn More
                  </Link> */}
                </span>
                <span>
                  <FaIndustry className="info-icon2" style={{ fill: "url(#gradient1)" }} />
                </span>
              </div>
            </div>
          </div>

          {/* Industry 2 - AI & Robotics */}
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">AI & Robotics</div>
              <p>Leverage artificial intelligence and robotics to improve automation and productivity in operations</p>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  {/* <Link to="/" className="lrnmore">
                    Learn More
                  </Link> */}
                </span>
                <span>
                  <FaRobot className="info-icon2" style={{ fill: "url(#gradient1)" }} />
                </span>
              </div>
            </div>
          </div>

          {/* Industry 3 - Mobile Technology */}
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Mobile Technology</div>
              <p>Innovate and optimize mobile experiences with cutting-edge mobile technology solutions</p>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  {/* <Link to="/" className="lrnmore">
                    Learn More
                  </Link> */}
                </span>
                <span>
                  <FaMobileAlt className="info-icon2" style={{ fill: "url(#gradient1)" }} />
                </span>
              </div>
            </div>
          </div>

          {/* Industry 4 - Cloud & DevOps */}
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Cloud & DevOps</div>
              <p>Enhance your business agility and scalability with cloud solutions and DevOps best practices</p>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  {/* <Link to="/" className="lrnmore">
                    Learn More
                  </Link> */}
                </span>
                <span>
                  <FaCloud className="info-icon2" style={{ fill: "url(#gradient1)" }} />
                </span>
              </div>
            </div>
          </div>

          {/* Industry 5 - Real Estate */}
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Real Estate</div>
              <p>Streamline property management and enhance the buying experience through innovative real estate technology</p>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  {/* <Link to="/" className="lrnmore">
                    Learn More
                  </Link> */}
                </span>
                <span>
                  <FaBuilding className="info-icon2" style={{ fill: "url(#gradient1)" }} />
                </span>
              </div>
            </div>
          </div>

          {/* Industry 6 - Automotive */}
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Automotive</div>
              <p>Optimize vehicle production and enhance customer experiences with smart automotive technologies</p>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  {/* <Link to="/" className="lrnmore">
                    Learn More
                  </Link> */}
                </span>
                <span>
                  <FaCar className="info-icon2" style={{ fill: "url(#gradient1)" }} />
                </span>
              </div>
            </div>
          </div>

          {/* Industry 7 - Healthcare */}
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Healthcare</div>
              <p>Leverage technology to streamline patient care, improve efficiency, and optimize medical practices</p>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  {/* <Link to="/" className="lrnmore">
                    Learn More
                  </Link> */}
                </span>
                <span>
                  <FaHospital className="info-icon2" style={{ fill: "url(#gradient1)" }} />
                </span>
              </div>
            </div>
          </div>

          {/* Industry 8 - Research & Development */}
          <div className="col-md-4 mb-3">
            <div className="bgsalt">
              <div className="loa">Research & Development</div>
              <p>Foster innovation by integrating technology into research processes and development cycles</p>
              <div className="text-end d-flex justify-content-between align-items-end">
                <span className="text-dark">
                  {/* <Link to="/" className="lrnmore">
                    Learn More
                  </Link> */}
                </span>
                <span>
                  <FaSearch className="info-icon2" style={{ fill: "url(#gradient1)" }} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industry;
