import React from "react";
import { Link } from "react-router-dom";
import {
  FaClipboardList,
  FaDraftingCompass,
  FaCode,
  FaCheckCircle,
  FaRocket,
  FaChalkboardTeacher,
  FaBuilding,
  FaOilCan,
  FaStore,
  FaShoppingCart,
  FaHeartbeat,
  FaIndustry,
} from "react-icons/fa";
import ailg from "../../components/img/star.svg";
import soft1 from "../../components/img/soft1.png";
import video1 from "../../components/img/video/video2.mp4";
import BookNow from "../../components/BookNow";
import Neuralink from "../../components/Neuralink";

const CustomSoftware = () => {
  return (
    <>
      <div className="bgsoftware py-5">
        <div className="container conttop">
          <div className="row">
            <div className="col-md-6">
              <h1 className="font2 qaw ">
                Custom Software Development Company
              </h1>
              <p>
                Our IT services are tailored to meet the unique technological
                needs of your organization and empower your employees with
                customized solutions.
              </p>
              <Link to="/" className="hirebtn2 mb-4">
                Get Quote Now
              </Link>
            </div>
          </div>
        </div>
      </div>


{/* <div className="contaner-fluid video-background"> */}
<div className="container my-5 ">
  {/* <video autoPlay muted loop className="background-video">
    <source src={video1} type="video/mp4"/>
    Your browser does not support the video tag.
  </video> */}

  <div className="row content-overlay">
    <div className="col-md-8">
      <h1 className="font2 qaw">
        Comprehensive <span className="aitxt">Software Development</span>{" "}
        Services
      </h1>
      <p>
        Custom web applications are essential for organizations that want to
        provide excellent user experiences and run efficiently in the digital
        age. We design and construct custom web apps for your business goals.
        For startups wanting a basic web app or major enterprises needing a
        complicated, feature-rich solution, we ensure that our applications are
        scalable, secure, and responsive across all devices. Our cross-industry
        knowledge lets us deliver online solutions that address problems, boost
        client engagement, and boost productivity.
      </p>

      <p>
        A seamless balance of aesthetics and functionality is key to web
        application success. This is why our web apps have distinctive UI/UX
        designs that stress usability and engagement. We also make our apps
        easy to maintain, upgrade, and connect with your existing systems,
        making them future-proof and flexible to your company needs.
      </p>
    </div>


    <div className="col-md-4">
      <img src={soft1} alt="Custom Software Solutions" className="img-fluid"/>
    </div>
  </div>
</div>
{/* </div> */}

      

      <div className="bg-white py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>How we Proceed</strong>
                </span>
              </div>

              <h1 className="font2 qaw text-dark text-start mb-5">
                Our <span className="aitxt">Process</span>
              </h1>
              <div className="d-flex justify-content-between align-items-center process-flow">
                <div className="process-step">
                  <FaClipboardList className="process-icon" />
                  <p>Requirement Analysis</p>
                  <div className="lap1">Understanding your business needs.</div>
                </div>
                <div className="arrow">&rarr;</div>
                <div className="process-step">
                  <FaDraftingCompass className="process-icon" />
                  <p>Planning</p>
                  <div className="lap1">
                    Designing a roadmap for your project.
                  </div>
                </div>
                <div className="arrow">&rarr;</div>
                <div className="process-step">
                  <FaCode className="process-icon" />
                  <p>Development</p>
                  <div className="lap1">
                    Building your software using the best practices.
                  </div>
                </div>
                <div className="arrow">&rarr;</div>
                <div className="process-step">
                  <FaCheckCircle className="process-icon" />
                  <p>Testing</p>
                  <div className="lap1">
                    Ensuring a bug-free and seamless experience.
                  </div>
                </div>
                <div className="arrow">&rarr;</div>
                <div className="process-step">
                  <FaRocket className="process-icon" />
                  <p>Deployment & Support</p>
                  <div className="lap1">
                    Launching your product and providing ongoing support.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for industry */}

      <div className="bg-white py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>Customized Software</strong>
                </span>
              </div>

              <h1 className="font2 qaw text-dark text-start mb-5">
                Customized Software Solutions For{" "}
                <span className="aitxt">Various Industries</span>
              </h1>

              {/* Define Linear Gradient */}
              <svg width="0" height="0">
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#1FB3FD", stopOpacity: 1 }}
                    />
                    <stop
                      offset="20%"
                      style={{ stopColor: "#8087F2", stopOpacity: 1 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: "#E957E7", stopOpacity: 1 }}
                    />
                    <stop
                      offset="77%"
                      style={{ stopColor: "#F359A5", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#FE5C5D", stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
              </svg>

              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaChalkboardTeacher
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Education</div>
                    <p>
                      IGN Softwares powers the Education and e-learning sectors
                      with digital solutions for engaging and accessible
                      learning. Our custom LMS systems, virtual classrooms, and
                      assessment tools foster interactive and easily accessible
                      learning experiences for both teachers and students.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaBuilding
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Real estate</div>
                    <p>
                      IGN Softwares provides modern tools for property
                      management, virtual tours, and client engagement in real
                      estate. Our custom property platforms, CRM systems, and
                      secure transaction management solutions streamline
                      processes and enhance customer interaction.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaOilCan
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Oil and gas</div>
                    <p>
                      IGN Softwares provides secure data processing and robust
                      systems for tracking equipment and ensuring safety
                      compliance in the oil and gas industry. We offer solutions
                      for asset tracking, predictive maintenance, and compliance
                      management to support safe and efficient operations.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaStore
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Retail</div>
                    <p>
                      IGN Softwares provides retail solutions that optimize
                      customer experiences, inventory management, and sales
                      channels. We develop omnichannel platforms, loyalty
                      programs, and POS systems to ensure seamless shopping
                      across digital and physical environments.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaShoppingCart
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">E-commerce</div>
                    <p>
                      IGN Softwares specializes in building custom e-commerce
                      platforms that streamline inventory control, payment
                      processing, and enhance the shopping experience. Our
                      solutions, including secure payment gateways and order
                      management systems, simplify operations and boost user
                      engagement.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaHeartbeat
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Healthcare</div>
                    <p>
                      IGN Softwares develops compliant, secure software that
                      improves operational efficiency and patient care in
                      healthcare. With a focus on patient data protection and
                      streamlined treatment processes, we create EHR systems,
                      telemedicine platforms, and healthcare management
                      solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>FAQ's</strong>
                </span>
              </div>

              <h1 className="font2 font3 qaw text-dark text-start mb-5">
                Frequently Asked <span className="aitxt">Questions</span>
              </h1>
            </div>

            <div className="col-md-6">
              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input type="checkbox" id="faq1" className="accordion-toggle" />
                  <label htmlFor= "faq1" className="accordion-header text-dark">
                    What is custom software development?
                  </label>
                  <div className="accordion-body">
                    Custom software development involves creating software
                    tailored to meet the unique requirements of a business or
                    individual, rather than using off-the-shelf solutions.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input type="checkbox" id="faq2" className="accordion-toggle" />
                  <label htmlFor="faq2" className="accordion-header text-dark">
                    How long does it take to develop custom software?
                  </label>
                  <div className="accordion-body">
                    The timeline for custom software development depends on the
                    complexity and scope of the project. Typically, it can take
                    anywhere from a few weeks to several months.
                  </div>
                </div>
              </div>



              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input type="checkbox" id="faq3" className="accordion-toggle" />
                  <label htmlFor="faq3" className="accordion-header text-dark">
                  What are the benefits of custom software?
                  </label>
                  <div className="accordion-body">
                  Custom software offers scalability, better integration with existing systems, improved efficiency, and a tailored solution that fits your exact needs.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input type="checkbox" id="faq4" className="accordion-toggle" />
                  <label htmlFor="faq4" className="accordion-header text-dark">
                  How much does custom software development cost?
                  </label>
                  <div className="accordion-body">
                  The cost varies depending on the project's size, complexity, required features, and technologies used. It's best to consult with a development team for a detailed estimate.
                  </div>
                </div>
              </div>


              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input type="checkbox" id="faq5" className="accordion-toggle" />
                  <label htmlFor="faq5" className="accordion-header text-dark">
                  Will I own the software after development?
                  </label>
                  <div className="accordion-body">
                  Yes, in most cases, you will own the rights to the custom software. Make sure this is clarified in the development contract.
                  </div>
                </div>
              </div>


              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input type="checkbox" id="faq6" className="accordion-toggle" />
                  <label htmlFor="faq6" className="accordion-header text-dark">
                  What technologies do you use for custom software development?
                  </label>
                  <div className="accordion-body">
                  We use a wide range of technologies, including React, Node.js, Python, Java, and others, depending on the specific project requirements.
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>



<BookNow/>



    </>
  );
};

export default CustomSoftware;
