import React from "react";
import { Helmet } from "react-helmet";
import HomeAnimation from "../components/HomeAnimation";
import ClientScroll from "../components/ClientScroll";
import InfoProject from "../components/InfoProject";
import WhyIGN from "../components/WhyIGN";
import OurService from "../components/OurService";
import DevLang from "../components/DevLang";
import Service from "../components/Service";
import Industry from "../components/Industry";
import ContactIgn from "../components/ContactIgn";
import logo from '../components/img/logo2.png'; 
const Home = () => {
  return (
    <div className="home-container">
      {/* SEO tags */}
      <Helmet>
        <title>Best IT Company in India- IGN SOFTWARES | Technology + Softwares</title>
        <meta
          name="description"
          content="Welcome to Your IT Company Name. We provide top-notch IT solutions, including web development, mobile app development, and more."
        />
        <meta
          name="keywords"
          content="IT services, web development, mobile app development, software solutions, custom IT solutions"
        />
        <meta name="author" content="IGN SOFTWARES" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:title" content="Home - IGN SOFTWARES | Technology + Softwares" />
        <meta
          property="og:description"
          content="Explore IT services and solutions tailored to your business needs."
        />
        <meta property="og:image" content="%PUBLIC_URL%/logo2.png" />

        <meta property="og:url" content="https://ignsoftwares.in/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://ignsoftwares.in/" />
      </Helmet>

      {/* Content */}
      <section className="hero-section position-relative">
        <HomeAnimation />
        <InfoProject />
        <Service />
        <OurService />
        <Industry />
        <WhyIGN />
        <DevLang />
        <ClientScroll />
        <div className="bgconsult2">
          <ContactIgn />
        </div>
      </section>
    </div>
  );
};

export default Home;
