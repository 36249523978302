import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import anime from "animejs/lib/anime.es.js";
import svg1 from "./img/svg1.svg";
import bgimg from "./img/bg1.webp";
import aimg1 from "./img/aimg1.webp"
const WhyIGN = () => {
  useEffect(() => {
    // Dot animation for each block
    anime({
      targets: ".dot",
      opacity: [0, 1],
      scale: [0.5, 1],
      easing: "easeInOutQuad",
      duration: 1000,
      delay: anime.stagger(200), // Delay for each dot
      loop: true,
      direction: "alternate", // Makes animation go back and forth
    });
  }, []);

  return (
    <div className="">
      <div className="container-fluid bgwhyign py-5  ">
        <h1 className="font2 qaw  text-white">
          <img src={svg1} />
          WHY <span className="aitxt">IGN SOFTWARES</span>
        </h1>

        <div className="row my-5">
          <div className="col-md-3">
            <div className="pl text-white">
              Expert Team
              <span className="dot">.</span>
            </div>
            <hr className="custom-hr" />
            <div className="pl1 txtit text-white">
              Highly skilled professionals specializing in cutting-edge
              technologies to deliver innovative solutions.
            </div>
          </div>

          <div className="col-md-3">
            <div className="pl text-white">
              Proven Track Record
              <span className="dot">.</span>
            </div>
            <hr className="custom-hr" />
            <div className="pl1 txtit text-white">
              Over 150+ successful projects, showcasing reliability and
              expertise in diverse industries.
            </div>
          </div>

          <div className="col-md-3">
            <div className="pl text-white">
              Client-Centric Approach
              <span className="dot">.</span>
            </div>
            <hr className="custom-hr" />
            <div className="pl1 txtit text-white">
              Dedicated to understanding client needs and delivering tailored,
              impactful solutions.
            </div>
          </div>

          <div className="col-md-3">
            <div className="pl text-white">
              Comprehensive Services
              <span className="dot">.</span>
            </div>
            <hr className="custom-hr" />
            <div className="pl1 txtit text-white">
              Offering end-to-end IT solutions, including development, support,
              and scalability for long-term success.
            </div>
          </div>
        </div>

<div className="row">
    <div className="col-md-8 d-flex align-items-center">
    <p className="text-white">
          At IGN Softwares, we prioritize your security and privacy, going
          beyond standard checklists to deliver tailored solutions that meet
          your unique needs. Backed by a highly skilled team and rigorous
          testing methodologies, we ensure thorough understanding and execution
          of penetration testing requirements. Our consultative approach helps
          clients fully comprehend our reports and assessments, while we provide
          expert guidance to strengthen their cybersecurity strategies and
          enhance their overall security posture.
        </p>
    </div>
    <div className="col-md-4">
        <img src={aimg1} className="img-fluid" />
    </div>
</div>

        
      </div>
    </div>
  );
};

export default WhyIGN;
