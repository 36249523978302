import React, { useState } from "react";
import axios from "axios";  // Import axios for handling HTTP requests
import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button components from react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import node from "./img/node.js.svg";
import ruby from "./img/ruby.svg";
import android from "./img/android.svg";
import aws from "./img/awsq.webp";
import cplusplus from "./img/cplusplus.svg";
import ailg from "./img/star.svg";

const ContactIgn = () => {
  // State to handle form data and submission status
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    countryCode: "+91",
    phoneNumber: "",
    email: "",
    message: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);  // For controlling submit button state
  const [error, setError] = useState("");  // To handle error message
  const [success, setSuccess] = useState("");  // To show success message
  
  // Modal state
  const [showModal, setShowModal] = useState(false); // Modal visibility

  // Handle form input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();  // Prevent the page reload on form submit
  
    console.log("Sending data:", formData);  // Log the form data before sending
  
    setIsSubmitting(true);  // Disable submit button while the request is in progress
  
    try {
      const response = await axios.post("https://ign.ignsoftwares.in/app/add-contact.php", formData);
      setIsSubmitting(false);
      if (response.status === 200) {
        setSuccess("Your message has been successfully sent!");
        setFormData({
          firstName: "",
          lastName: "",
          countryCode: "+91",
          phoneNumber: "",
          email: "",
          message: ""
        });
        // Show the success modal after successful submission
        setShowModal(true);
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } catch (err) {
      setIsSubmitting(false);
      setError("Error: Unable to send your message. Please try again later.");
    }
  };

  // Close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="container py-3">
        <div className="my-3 text-center">
          <div className="">
            <div>
              <h1 className="text-white qaw font2 text-center">
                Need assistance with a <span className="aitxt">software project?</span>
              </h1>
            </div>
            <div>
              <p className="text-white">
                Whether you're looking for expert developers or a full-service development solution, we're here to help. Get in touch!
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-8 ">
            <div className="blck2 p-4 ">
              <form onSubmit={handleSubmit}>
                {/* First Name and Last Name */}
                <div className="row mb-1">
                  <div className="col-md-6">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="form-control inputfrom"
                      placeholder="Enter your first name"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="form-control inputfrom"
                      placeholder="Enter your last name"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                {/* Country Code and Phone Number */}
                <div className="row mb-1">
                  <div className="col-md-4">
                    <label htmlFor="countryCode" className="form-label ">
                      Country Code
                    </label>
                    <select
                      id="countryCode"
                      className="form-select inputfrom"
                      value={formData.countryCode}
                      onChange={handleChange}
                      required
                    >
                      <option value="+91">+91 (India)</option>
                      <option value="+1">+1 (USA)</option> 
                      <option value="+44">+44 (UK)</option>
                      <option value="+61">+61 (Australia)</option>
                      <option value="+81">+81 (Japan)</option>
                    </select>
                  </div>
                  <div className="col-md-8">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phoneNumber"
                      className="form-control inputfrom"
                      placeholder="Enter your phone number"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                {/* Email */}
                <div className="mb-1">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="form-control inputfrom"
                    placeholder="Enter Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Message */}
                <div className="mb-1">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    id="message"
                    className="form-control inputfrom inputfromtxt"
                    rows="5"
                    placeholder="Type your message here"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                  <button type="submit" className="hirebtn2" disabled={isSubmitting}>
                    {isSubmitting ? "Sending..." : "Submit"}
                  </button>
                </div>
              </form>

              {/* Display Error/Success messages */}
              {error && <div className="alert alert-danger mt-3">{error}</div>}
            </div>
          </div>

          <div className="col-md-4">
            <div className="blck2 p-4">
              <h6>Email Us at:</h6>
              <p className="m-0">Contact@ignsoftwares.in</p>
              <p>Support@ignsoftwares.in</p>

              <h6>Call Us at:</h6>
              <p className="m-0"></p>
              <p>+91 853 078 0242</p>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
     
    </div>
  );
};

export default ContactIgn;
