import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  FaEye,
  FaGavel,
  FaCode,
  FaSearch,
  FaRocket,
  FaChalkboardTeacher,
  FaBuilding,
  FaOilCan,
  FaStore,
  FaShoppingCart,
  FaHeartbeat,
  FaIndustry,
} from "react-icons/fa";
import ailg from "../components/img/star.svg";
import mobile3 from "../components/img/mobile3.png";
import video1 from "../components/img/video/video2.mp4"; 
import WhyIGN from "../components/WhyIGN";
import BookNow from "../components/BookNow";


const About = () => {
  return (
    <>


{/* SEO tags */}
<Helmet>
  <title>About Us - IGN SOFTWARES | Technology + Softwares</title>
  <meta
    name="description"
    content="Learn more about IGN SOFTWARES, our journey, expertise in IT solutions, and our commitment to delivering innovative software and technology services."
  />
  <meta
    name="keywords"
    content="About IGN SOFTWARES, IT solutions, software company, technology services, company history, IT expertise"
  />
  <meta name="author" content="IGN SOFTWARES" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta property="og:title" content="About Us - IGN SOFTWARES | Technology + Softwares" />
  <meta
    property="og:description"
    content="Discover IGN SOFTWARES and our passion for delivering custom IT and software solutions to help businesses thrive in the digital age."
  />
  <meta property="og:image" content="%PUBLIC_URL%/logo2.png" />
  <meta property="og:url" content="https://ignsoftwares.in/about" />
  <meta property="og:type" content="website" />
  <link rel="canonical" href="https://ignsoftwares.in/about-us" />
</Helmet>


      <div className="ptii bgwhyign1">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <div className="text-white d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>About US</strong>
                </span>
              </div>

              <h1 className="font2 font3 qaw text-white text-start mb-5">
                Who <span className="aitxt">we </span> Are
              </h1>
            </div>

            <div className="col-md-6">
              <div className="font4 abt1 text-white">
                At <strong>IGN SOFTWARES</strong>, we are driven by a passion
                for technology and a commitment to delivering innovative
                software solutions that meet the unique needs of businesses
                across various industries. As a full-service software
                development company, we specialize in creating cutting-edge
                applications, custom software, and digital platforms designed to
                solve real-world problems and optimize business processes.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bgwhyign1">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 class="text-white qaw font2 text-center mb-5">5+ Team</h1>
              <div className="d-flex justify-content-center">
                <p className="text-white text-capitalize text-center maxwd font4">
                  We carefully assemble teams with professionals who possess not
                  only technical proficiency but also a keen understanding of
                  your industry, market trends, and specific business
                  challenges. These experts work closely with you to craft
                  solutions that are not only technically sound but also
                  strategically aligned with your goals. Whether it's
                  healthcare, finance, e-commerce, or any other niche sector,
                  our teams have the knowledge and experience to drive impactful
                  results.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <h1 class="text-white qaw font2 text-center mb-5">
                30+ Engineers
              </h1>
              <div className="d-flex justify-content-center">
                <p className="text-white text-capitalize text-center maxwd font4">
                  Each member of our team is handpicked not only for their
                  technical expertise but also for their curiosity and
                  commitment to continuous learning. This means that they are
                  always up-to-date with the latest trends, tools, and
                  technologies, ensuring that your project benefits from
                  cutting-edge solutions. Whether it's optimizing performance,
                  enhancing user experience, or building scalable systems, our
                  team is eager to think outside the box and come up with
                  creative ways to address challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bgwhyign1 bgworldmap">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-8">
              <h1 class="text-white qaw font2 text-end mb-5"></h1>
              <div className="">
                <p className="text-white text-capitalize text-end maxwd2 font4">
                  Our team of skilled developers, designers, and tech
                  enthusiasts work collaboratively to bring your ideas to life,
                  from concept to completion. With expertise in mobile, web, and
                  enterprise solutions, we focus on delivering high-quality
                  products that provide measurable results. We combine technical
                  proficiency with a user-centric approach, ensuring that every
                  product we build not only meets your business objectives but
                  also delights your users.
                </p>

                <p className="text-white text-capitalize text-end maxwd2 font4">
                  We pride ourselves on our customer-first philosophy, offering
                  transparent communication, flexibility, and agile development
                  practices to ensure your vision is realized. Whether you're a
                  startup looking to disrupt your industry or an established
                  company aiming to innovate, <strong>IGN SOFTWARES</strong> is your
                  trusted partner for all your software development needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


<WhyIGN/>
 <BookNow/>   
    </>
  );
};

export default About;
