import React from "react";
import { Link } from "react-router-dom";
import {
  FaEye,
  FaGavel,
  FaCode,
  FaSearch,
  FaRocket,
  FaChalkboardTeacher,
  FaBuilding,
  FaOilCan,
  FaStore,
  FaShoppingCart,
  FaHeartbeat,
  FaIndustry,
} from "react-icons/fa";
import ailg from "../../components/img/star.svg";
import mobile3 from "../../components/img/mobile3.png";
import video1 from "../../components/img/video/video2.mp4";
import BookNow from "../../components/BookNow";
import Neuralink from "../../components/Neuralink";
import DevLang from "../../components/DevLang";

const MobileDevelopemnt = () => {
  return (
    <>
      <div className="bgmobile py-5">
        <div className="container conttop">
          <div className="row">
            <div className="col-md-6">
              <h1 className="font2 qaw ">Mobile Application Development</h1>
              <p>
                Our distinctive business model cultivates dedicated development
                teams that deliver top-tier solutions, empowering clients to
                tackle challenges with custom-built technology tailored to their
                needs.
              </p>
              <Link to="/" className="hirebtn2 mb-4">
                Get Quote Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="contaner-fluid video-background"> */}
      <div className="container my-5 ">
        {/* <video autoPlay muted loop className="background-video">
    <source src={video1} type="video/mp4"/>
    Your browser does not support the video tag.
  </video> */}

        <div className="row content-overlay">
          <div className="col-md-8">
            <h1 className="font2 qaw">
              Comprehensive{" "}
              <span className="aitxt">Mobile App Development</span> Services
            </h1>
            <p>
              In today's mobile-first world, a strong mobile presence is
              essential for businesses to connect with customers and stay
              competitive. We specialize in developing custom mobile
              applications that are intuitive, responsive, and scalable across
              both iOS and Android platforms. Whether you're building a new app
              or enhancing an existing one, our mobile development services are
              designed to help you achieve your business goals.
            </p>

            <p>
              Our mobile app development services encompass everything from
              concept and design to development and deployment. We work closely
              with you to understand your specific requirements and create
              user-friendly apps that deliver seamless experiences. Whether you
              need an e-commerce platform, enterprise solution, or a mobile app
              for customer engagement, our team has the expertise to bring your
              ideas to life.
            </p>
            <p>
              By using the latest technologies, agile methodologies, and
              industry best practices, we ensure that your mobile apps are fast,
              secure, and optimized for performance. We also focus on
              scalability and long-term support to ensure that your app
              continues to grow with your business. With a strong emphasis on
              user experience, performance, and functionality, we help you
              deliver innovative mobile solutions that drive engagement and
              growth.
            </p>
          </div>

          <div className="col-md-4">
            <img
              src={mobile3}
              alt="Custom Software Solutions"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="bg-white py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>What we offer</strong>
                </span>
              </div>

              <h1 className="font2 qaw text-dark text-start mb-5">
              Mobile app development {" "}
                <span className="aitxt">from the start</span>
                 
              </h1>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center process-flow">
                <div className="arrow">.01</div>
                <div className="process-step">
                  {/* <FaClipboardList className="process-icon" /> */}
                  <p>Audit and consulting</p>
                  <div className="lap1">
                  Comprehensive assessment identifying action points and areas for improvement.
                  Detailed product roadmap.
                  </div>
                </div>
                <div className="arrow">.02</div>
                <div className="process-step">
                  {/* <FaDraftingCompass className="process-icon" /> */}
                  <p>Architecture designing</p>
                  <div className="lap1">
                  Scalable architecture.
                  Clear design patterns.
                  </div>
                </div>
                <div className="arrow">.03</div>
                <div className="process-step">
                  {/* <FaCode className="process-icon" /> */}
                  <p>UI/UX design</p>
                  <div className="lap1">
                  Clear and engaging interfaces.
                  Smooth friction-free user experience.
                  </div>
                </div>
                <div className="arrow">.04</div>
                <div className="process-step">
                   
                  <p>Migration and modernization</p>
                  <div className="lap1">
                  Upgrade to the latest technology standards.
                  Enhanced security and scalability.
                  </div>
                </div>
                <div className="arrow">.05</div>
                <div className="process-step">
                  {/* <FaRocket className="process-icon" /> */}
                  <p>Testing</p>
                  <div className="lap1">Holistic QA (testing throughout the whole development cycle)
                  Compliance with regulations (GDPR, HIPAA, PSI DSS, and FISMA)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for industry */}

      <div className="bg-white py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>Custom Applications</strong>
                </span>
              </div>

              <h1 className="font2 qaw text-dark text-start mb-5">
              Our {" "}
                <span className="aitxt">Mobile Application Development </span>Services
              </h1>

              {/* Define Linear Gradient */}
              <svg width="0" height="0">
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#1FB3FD", stopOpacity: 1 }}
                    />
                    <stop
                      offset="20%"
                      style={{ stopColor: "#8087F2", stopOpacity: 1 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: "#E957E7", stopOpacity: 1 }}
                    />
                    <stop
                      offset="77%"
                      style={{ stopColor: "#F359A5", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#FE5C5D", stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
              </svg>

              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaGavel
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Native Mobile App Development</div>
                    <p>
                      We are pleased to offer customized iOS and Android
                      application development solutions designed to meet your
                      specific needs and objectives. Adhering to the highest
                      standards of native app development, we guarantee a smooth
                      and hassle-free submission process to both the App Store
                      and Play Store.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaEye
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">
                      Hybrid Mobile App Development Services
                    </div>
                    <p>
                      If you're looking to launch quickly and cost-effectively
                      across multiple platforms, our hybrid app development
                      approach blends the flexibility of web technologies with
                      the performance of native features.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaSearch
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">
                      Cross-platform Mobile Applications
                    </div>
                    <p>
                      By overcoming the challenges of cross-platform software
                      development, we efficiently scale app creation across
                      multiple operating systems, cutting both time and costs
                      while maintaining consistent performance. The outcome? A
                      fast launch for your business and a maximized ROI.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>FAQ's</strong>
                </span>
              </div>

              <h1 className="font2 font3 qaw text-dark text-start mb-5">
                Frequently Asked <span className="aitxt">Questions</span>
              </h1>
            </div>

            <div className="col-md-6">
              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq1"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq1" className="accordion-header text-dark">
                  What platforms do you develop mobile apps for?
                  </label>
                  <div className="accordion-body">
                  We specialize in developing mobile apps for both iOS and Android platforms. We also offer hybrid solutions for cross-platform apps to ensure your product reaches a broader audience without sacrificing performance.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq2"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq2" className="accordion-header text-dark">
                  What is the difference between native and hybrid app development?
                  </label>
                  <div className="accordion-body">
                  Native apps are developed specifically for one platform (iOS or Android), ensuring optimal performance. Hybrid apps, on the other hand, are built using web technologies and work across both platforms, providing a cost-effective solution for businesses looking to reach multiple audiences with a single app.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq3"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq3" className="accordion-header text-dark">
                  How long does it take to develop a mobile app?
                  </label>
                  <div className="accordion-body">
                  The development timeline varies depending on the complexity of the app, features, and design requirements. Typically, it takes anywhere from a few weeks to several months to launch a fully functional app. We will provide a more precise timeline after discussing your project’s specific needs.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq4"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq4" className="accordion-header text-dark">
                  How much does it cost to develop a mobile app?
                  </label>
                  <div className="accordion-body">
                  The cost of developing a mobile app depends on several factors such as the platform, features, complexity, and design. We provide customized solutions tailored to your budget, ensuring you get the most value from your investment.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq5"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq5" className="accordion-header text-dark">
                  Will I be able to manage and update my app after it’s developed?
                  </label>
                  <div className="accordion-body">
                  Yes, we offer post-launch support and maintenance services, including app updates, bug fixes, and feature enhancements. You will also have access to the backend (if applicable) to manage content and other aspects of your app.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq6"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq6" className="accordion-header text-dark">
                  Do you assist with app submission to the App Store and Google Play?
                  </label>
                  <div className="accordion-body">
                  Absolutely! We handle the entire process of submitting your app to the App Store and Google Play, ensuring it meets all the guidelines for a smooth approval process. We also offer guidance and support for app updates and version management.
                  </div>
                </div>
              </div>

               
            </div>
          </div>
        </div>
      </div>

      <BookNow />
    </>
  );
};

export default MobileDevelopemnt;
