import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import logo1 from "./img/logo/logo2.webp";
import logo2 from "./img/logo/logo3.webp";
import logo3 from "./img/logo/logo4.webp";
import logo4 from "./img/logo/logo5.webp";
// import logo5 from "./img/logo/logo6.webp";
import logo6 from "./img/logo/logo7.webp";
// import logo7 from "./img/logo/logo8.webp";
import logo8 from "./img/logo/logo9.webp";
import logo9 from "./img/logo/logo10.webp";

// Store all logos in an array
const clientLogos = [
//   { id: 1, src: logo1, alt: "Client Logo 1" },
  { id: 2, src: logo2, alt: "Client Logo 2" },
  { id: 3, src: logo3, alt: "Client Logo 3" },
  { id: 4, src: logo4, alt: "Client Logo 4" },
//   { id: 5, src: logo5, alt: "Client Logo 5" },
  { id: 6, src: logo6, alt: "Client Logo 6" },
//   { id: 7, src: logo7, alt: "Client Logo 7" },
  { id: 8, src: logo8, alt: "Client Logo 8" },
  { id: 9, src: logo9, alt: "Client Logo 9" },
  { id: 10, src: logo1, alt: "Client Logo 10" }, 
];

const ClientScroll = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000, // Adjusted speed for continuous scrolling
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Autoplay without any delay for continuous scroll
    cssEase: "linear", // Linear scrolling for continuous effect
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="home-container">
      <section className="hero-section position-relative">
        <div className="container text-center">
          {/* <h2 className="mb-4 text-dark">Our Trusted Clients</h2> */}
          <Slider {...settings}>
            {clientLogos.map((logo, index) => (
              <div
                key={index}
                className="client-logo-item text-center"
              >
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className="img-fluid logo-item grayscale"
                />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default ClientScroll;
