import React from "react";
import node from "./img/node.js.svg";
import ruby from "./img/ruby.svg";
import android from "./img/android.svg";
import aws from "./img/awsq.webp";
// import cib_dotnet from "./img/cib_dot-net.svg";
import cplusplus from "./img/cplusplus.svg";
import devicon_angular from "./img/devicon_angular.svg";
import devicon from "./img/devicon_dot-net.svg";
import flutter from "./img/flutter.svg";
import golang from "./img/golang.svg";
import ios from "./img/ios.png";
import java from "./img/java.svg";
import logos_php from "./img/logos_php.svg";
import logos_react from "./img/logos_react.svg";
import logos_vue from "./img/logos_vue.svg";
import msazure from "./img/ms-azure.svg";
import python from "./img/python.svg";
import ailg from "./img/star.svg";
const DevLang = () => {
  const logos = [
    node,
    ruby,
    android,
    aws,
    // cib_dotnet,
    cplusplus,
    devicon_angular,
    devicon,
    flutter,
    golang,
    ios,
    java,
    logos_php,
    logos_react,
    logos_vue,
    msazure,
    python,
  ];

  return (
    <div className=" bgwhyignimg">
      <div className="container py-5 ">
        <div className="my-3 text-center">
          <div className="text-dark d-flex">
            <span>
              <span className="star-block d-inline-block">
                <img src={ailg} alt="" className="star1" />
                <img src={ailg} alt="" className="star2" />
                <img src={ailg} alt="" className="star3" />
              </span>
            </span>
            <span>
              <strong>Our Tech Stack</strong>
            </span>
          </div>

          <div className="d-flex justify-content-between  align-items-center">
            <div >
              <h1 className="text-dark qaw font2 text-start">
                We Cover <span className="aitxt">YourStack</span>
              </h1>
            </div>
            <div>
              <p className="text-dark devtxt">
                We’re well-versed in most programming languages, tools, and
                frameworks essential for innovative solutions. We will provide
                the perfect combination based on your business needs, our tech
                stack, and best industry practices.
              </p>
            </div>
          </div>

          {/* <h1 className="text-dark udrl qaw">We Cover Your Stack</h1> */}
        </div>
        <div className="row justify-content-center">
          {logos.map((logo, index) => (
            <div
              key={index}
              className="col-md-1 col-2 d-flex align-items-center justify-content-center logo-container"
            >
              <img
                src={logo}
                alt={`logo-${index}`}
                className="img-fluid wave-logo logohvr"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DevLang;
