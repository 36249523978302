import React from "react";
import { Link } from "react-router-dom";

// Example icons (You can replace them with your own or use libraries like Font Awesome)
import { FaUsers, FaProjectDiagram, FaBuilding, FaSmile, FaCertificate, FaClock } from "react-icons/fa";

const InfoProject = () => {
  return (
    <div className="info-project-container">
      <div className="info-stats">
        <div className="info-item">
          <FaUsers className="info-icon" />
          <p className="info-number">10+</p>
          <hr className="custom-hr"/>
          <p className="info-label">Staff Members</p>
          
        </div>
        <div className="info-item">
          <FaProjectDiagram className="info-icon" />
          <p className="info-number">150+</p>
          <hr className="custom-hr"/>
          <p className="info-label">Projects</p>
        </div>
        {/* <div className="info-item">
          <FaBuilding className="info-icon" />
          <p className="info-number">5</p>
          <p className="info-label">Offices</p>
        </div> */}
        <div className="info-item">
          <FaSmile className="info-icon" />
          <p className="info-number">100+</p>
          <hr className="custom-hr"/>
          <p className="info-label">Happy Clients</p>
        </div>
        <div className="info-item">
          <FaCertificate className="info-icon" />
          <p className="info-number">4</p>
          <hr className="custom-hr"/>
          <p className="info-label">Certified PMP</p>
        </div>
        <div className="info-item">
          <FaClock className="info-icon" />
          <p className="info-number">100</p>
          <hr className="custom-hr"/>
          <p className="info-label">MM Lines of Code</p>
        </div>
      </div>
    </div>
  );
};

export default InfoProject;
