import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from './img/logo2.png'; 

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);  // State to manage menu collapse

  const toggleMenu = () => setIsOpen(!isOpen);  // Function to toggle the menu

  return (
    <>

    <section className="container py-2 sticktop">
    <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          {/* Logo */}
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid logo" alt="Logo" />
          </Link>

          {/* Toggler for mobile view */}
          <button
            className="navbar-toggler p-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={isOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}  // Toggle collapse state
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar links */}
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contact</Link>
              </li>

              {/* Services Dropdown */}
              <li className="nav-item dropdown custom-dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="servicesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded={isOpen ? "true" : "false"}
                >
                  Services
                </a>
                <ul className="dropdown-menu" aria-labelledby="servicesDropdown">
                  <li><Link className="dropdown-item" to="/custom-software-development">Custom Software Development</Link></li>
                  <li><Link className="dropdown-item" to="/backend-development">Backend Development</Link></li>
                  <li><Link className="dropdown-item" to="/ai-software-development">AI Software Development</Link></li>
                  <li><Link className="dropdown-item" to="/mobile-app-development">Mobile App Development</Link></li>
                </ul>
              </li>

              {/* Solutions Dropdown */}
              {/* <li className="nav-item dropdown custom-dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="solutionsDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded={isOpen ? "true" : "false"}
                >
                  Solutions
                </a>
                <ul className="dropdown-menu" aria-labelledby="solutionsDropdown">
                  <li><Link className="dropdown-item" to="/solutions/solution1">Solution 1</Link></li>
                  <li><Link className="dropdown-item" to="/solutions/solution2">Solution 2</Link></li>
                  <li><Link className="dropdown-item" to="/solutions/solution3">Solution 3</Link></li>
                </ul>
              </li> */}

              <li className="nav-item">
                <Link className="nav-link " to="/contact"><span className="hirebtn">Hire Us</span></Link>
              </li>


            </ul>
          </div>
        </div>
      </nav>
    </section>
      
    </>
  );
};

export default Header;
