import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaGithub } from "react-icons/fa";
import imgfoot from "./img/logo2.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer style={{ backgroundColor: "#fff", color: "#000", padding: "0px 20px" }}>
      {/* <hr className="custom-hr"/> */}
      <div className="container mt-5">
        <div className="row">
          {/* About Us Section */}
          <div className="col-md-5 text-center text-md-start mb-4">
            <img src={imgfoot} alt="Footer Logo" style={{ maxWidth: "150px", marginBottom: "15px" }} />
            <p className="txtit">
              IGN Softwares Pvt Ltd is a leading provider of software solutions, dedicated to delivering innovative and
              reliable services to help businesses grow and succeed. Our expertise spans across web development, mobile
              apps, cloud services, and more.
            </p>
          </div>

          {/* Quick Links */}
          <div className="col-md-2 text-center text-md-start mb-4">
            <h5 className="pl">Quick Links</h5>
            <ul style={styles.list}>
              <li>
                <Link to="/about" style={styles.link}>About Us</Link>
              </li>
              {/* <li>
                <Link href="/services" style={styles.link}>Services</Link>
              </li> */}
              {/* <li>
                <Link to="/careers" style={styles.link}>Careers</Link>
              </li> */}
              <li>
                <Link to="/contact" style={styles.link}>Contact Us</Link>
              </li>
            </ul>
          </div>

          {/* Services */}
          <div className="col-md-3 text-center text-md-start mb-4">
            <h5 className="pl">Our Services</h5>
            <ul style={styles.list}>
              <li>
                <Link to="/custom-software-development" style={styles.link}>Custom Software Development</Link>
              </li>
              <li>
                <Link to="/backend-development" style={styles.link}>Backend Development</Link>
              </li>
              <li>
                <Link to="/ai-software-development" style={styles.link}>AI Software Development</Link>
              </li>
              <li>
                <Link to="/mobile-app-development" style={styles.link}>Mobile App Development</Link>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="col-md-2 text-center text-md-start mb-4">
            <h5 className="pl">Contact Us</h5>
            {/* <p><strong>Address:</strong> 123 Business St, City, Country</p> */}
            <p className="m-0"><strong>Phone:</strong> +91 853 078 0242</p>
            <p className="m-0"><strong>Email:</strong> contact@ignsoftwares.in</p>
          </div>
        </div>

        <hr className="custom-hr"/>

        {/* Social Media Links and Copyright */}
        <div className="text-center mt-4">
          {/* Social Media Links */}
          <div className="mb-3">
            <a href="#"  rel="noopener noreferrer" style={styles.iconLink}>
              <FaFacebook style={styles.icon} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer" style={styles.iconLink}>
              <FaTwitter style={styles.icon} />
            </a>
            <a href="https://in.linkedin.com/company/ign-softwares-pvt-ltd?trk=public_profile_topcard-current-company" target="_blank" rel="noopener noreferrer" style={styles.iconLink}>
              <FaLinkedin style={styles.icon} />
            </a>
            <a href="https://www.instagram.com/ignsoftwares/" target="_blank" rel="noopener noreferrer" style={styles.iconLink}>
              <FaInstagram style={styles.icon} />
            </a>
            {/* <a href="https://github.com" target="_blank" rel="noopener noreferrer" style={styles.iconLink}>
              <FaGithub style={styles.icon} />
            </a> */}
          </div>

          {/* Footer Links */}
          {/* <div className="mb-3">
            <Link to="/terms" style={styles.link}>Terms & Conditions</Link> | 
            <Link to="/privacy" style={styles.link}> Privacy Policy</Link>
          </div> */}
          <hr className="custom-hr"/>
          {/* Footer Text */}
          <p>Copyright © {new Date().getFullYear()} IGN Softwares Pvt Ltd. All Rights Reserved.</p>
          <p>CIN : U72200MH2022PTC394044</p>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  icon: {
    fontSize: "24px",
    margin: "0 10px",
    color: "#000",
    transition: "color 0.3s ease",
  },
  iconLink: {
    textDecoration: "none",
  },
  list: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },
  link: {
    color: "#000",
    textDecoration: "none",
    marginBottom: "8px",
    display: "block",
    transition: "color 0.3s ease",
  },
};

export default Footer;
