import React from "react";
import { Link } from "react-router-dom";
import {
  FaEye,
  FaGavel,
  FaCode,
  FaSearch,
  FaRocket,
  FaChalkboardTeacher,
  FaBuilding,
  FaOilCan,
  FaStore,
  FaShoppingCart,
  FaHeartbeat,
  FaIndustry,
} from "react-icons/fa";
import ailg from "../../components/img/star.svg";
import ai from "../../components/img/ai2.gif";
import video1 from "../../components/img/video/video2.mp4";
import BookNow from "../../components/BookNow";
import Neuralink from "../../components/Neuralink";
import DevLang from "../../components/DevLang";

const AiSoftwareDevelopemnt = () => {
  return (
    <>
      <div className="bgai py-5">
        <div className="container conttop">
          <div className="row">
            <div className="col-md-6">
              <h1 className="font2 qaw ">
                Artificial Inteligence Development Services
              </h1>
              <p>
                Ensure security, make accurate business predictions, automate
                key processes, and enhance customer experiences by personalizing
                interactions rather than relying on generic segmentation.
              </p>
              <Link to="/" className="hirebtn2 mb-4">
                Get Quote Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="contaner-fluid video-background"> */}
      <div className="container my-5 ">
        {/* <video autoPlay muted loop className="background-video">
    <source src={video1} type="video/mp4"/>
    Your browser does not support the video tag.
  </video> */}

        <div className="row content-overlay">
          <div className="col-md-8">
            <h1 className="font2 qaw">
              Comprehensive{" "}
              <span className="aitxt">AI Software Development</span> Services
            </h1>
            <p>
              AI-driven software is transforming industries by enabling
              businesses to make smarter decisions, automate complex processes,
              and deliver personalized experiences at scale. We specialize in
              developing cutting-edge AI software solutions that leverage
              machine learning, natural language processing, and data analytics
              to enhance your business operations.
            </p>

            <p>
              Our AI software development services include building custom AI
              models, integrating AI into existing systems, and optimizing
              AI-driven processes for better efficiency and accuracy. Whether
              you need a recommendation engine, predictive analytics, or
              intelligent automation, we provide solutions tailored to your
              specific business needs.
            </p>
            <p>
              We use advanced algorithms, data-driven insights, and the latest
              AI technologies to create software that learns, adapts, and
              evolves with your business. From data collection and processing to
              model training and deployment, our team ensures that your AI
              solutions are scalable, secure, and built to deliver impactful
              results. By combining innovation, intelligence, and industry
              expertise, we empower your organization to harness the power of AI
              to stay competitive and drive future growth.
            </p>
          </div>

          <div className="col-md-4">
            <img
              src={ai}
              alt="Custom Software Solutions"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="bg-white py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>What we offer</strong>
                </span>
              </div>

              <h1 className="font2 qaw text-dark text-start mb-5">
                With our artificial intelligence{" "}
                <span className="aitxt">software development </span>services
              </h1>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center process-flow">
                <div className="arrow">.01</div>
                <div className="process-step">
                  {/* <FaClipboardList className="process-icon" /> */}
                  <p>AI Technology Consulting and Advisory Services</p>
                  <div className="lap1">
                    Our AI consulting services provide expert guidance to help
                    you navigate the AI landscape. Whether you're new to AI or
                    an experienced user, we offer tailored advice to make AI
                    work for your business.
                  </div>
                </div>
                <div className="arrow">.02</div>
                <div className="process-step">
                  {/* <FaDraftingCompass className="process-icon" /> */}
                  <p>AI development services</p>
                  <div className="lap1">
                    Our AI development services specialize in creating advanced
                    AI solutions, from chatbots to image recognition, bringing
                    your projects to life with expertise and innovation.
                  </div>
                </div>
                <div className="arrow">.03</div>
                <div className="process-step">
                  {/* <FaCode className="process-icon" /> */}
                  <p>AI integration</p>
                  <div className="lap1">
                    Our AI integration services seamlessly incorporate AI into
                    your existing systems, optimizing your tech stack for
                    maximum efficiency and minimal hassle.
                  </div>
                </div>
                <div className="arrow">.04</div>
                <div className="process-step">
                  {/* <FaCheckCircle className="process-icon" /> */}
                  <p>AI-powered data and analytics</p>
                  <div className="lap1">
                    Our data science services unlock valuable insights through
                    predictive analytics, data visualization, and machine
                    learning, helping you make data-driven decisions.
                  </div>
                </div>
                <div className="arrow">.05</div>
                <div className="process-step">
                  {/* <FaRocket className="process-icon" /> */}
                  <p>Connect Us</p>
                  <div className="lap1">Let's Discuss your project today.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for industry */}

      <div className="bg-white py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-12">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>Artificial Intelligence</strong>
                </span>
              </div>

              <h1 className="font2 qaw text-dark text-start mb-5">
                We Guide You{" "}
                <span className="aitxt">For Artificial Intelligence With</span>
              </h1>

              {/* Define Linear Gradient */}
              <svg width="0" height="0">
                <defs>
                  <linearGradient
                    id="gradient1"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop
                      offset="0%"
                      style={{ stopColor: "#1FB3FD", stopOpacity: 1 }}
                    />
                    <stop
                      offset="20%"
                      style={{ stopColor: "#8087F2", stopOpacity: 1 }}
                    />
                    <stop
                      offset="50%"
                      style={{ stopColor: "#E957E7", stopOpacity: 1 }}
                    />
                    <stop
                      offset="77%"
                      style={{ stopColor: "#F359A5", stopOpacity: 1 }}
                    />
                    <stop
                      offset="100%"
                      style={{ stopColor: "#FE5C5D", stopOpacity: 1 }}
                    />
                  </linearGradient>
                </defs>
              </svg>

              <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaGavel
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Bias-free AI</div>
                    <p>
                      We prioritize creating AI systems that are free from bias,
                      ensuring fairness and impartiality in decision-making. Our
                      approach guarantees that AI-driven outcomes are equitable
                      and inclusive, supporting diverse needs and perspectives.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaEye
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Transparency</div>
                    <p>
                      We believe in transparency in AI, providing clear insights
                      into how our models and algorithms function. This ensures
                      you understand the decision-making process, fostering
                      trust and accountability in every AI solution we deliver.
                    </p>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bgsalt">
                    <div className="">
                      <span>
                        <FaSearch
                          className="info-icon2"
                          style={{ fill: "url(#gradient1)" }}
                        />
                      </span>
                    </div>
                    <div className="loa">Ongoing Audits</div>
                    <p>
                      We conduct ongoing audits to ensure our AI systems adhere
                      to ethical guidelines and industry standards. This
                      continuous review process helps maintain fairness,
                      accountability, and transparency, ensuring that our AI
                      solutions remain reliable and responsible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

 

      <div className="bg-light py-5">
        <div className="container">
          {" "}
          {/* Use container-fluid for full-width layout */}
          <div className="row">
            <div className="col-md-6">
              <div className="text-dark d-flex">
                <span>
                  <span className="star-block d-inline-block">
                    <img src={ailg} alt="" className="star1" />
                    <img src={ailg} alt="" className="star2" />
                    <img src={ailg} alt="" className="star3" />
                  </span>
                </span>
                <span>
                  <strong>FAQ's</strong>
                </span>
              </div>

              <h1 className="font2 font3 qaw text-dark text-start mb-5">
                Frequently Asked <span className="aitxt">Questions</span>
              </h1>
            </div>

            <div className="col-md-6">
              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq1"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq1" className="accordion-header text-dark">
                    What is AI software development?
                  </label>
                  <div className="accordion-body">
                    AI software development involves creating intelligent
                    systems that can simulate human-like behaviors such as
                    learning, reasoning, and decision-making. These systems use
                    machine learning, natural language processing, computer
                    vision, and other AI technologies to solve problems and
                    improve automation across various industries.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq2"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq2" className="accordion-header text-dark">
                    How can AI software improve my business operations?
                  </label>
                  <div className="accordion-body">
                    AI can streamline operations by automating repetitive tasks,
                    enhancing decision-making with data-driven insights,
                    personalizing customer interactions, and optimizing resource
                    management. It can also help predict market trends, detect
                    fraud, and improve customer service through chatbots and
                    virtual assistants.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq3"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq3" className="accordion-header text-dark">
                    What industries benefit the most from AI software
                    development?
                  </label>
                  <div className="accordion-body">
                    AI can benefit numerous industries including healthcare,
                    finance, retail, automotive, manufacturing, and customer
                    service. Each of these industries can leverage AI to improve
                    efficiency, accuracy, and overall customer satisfaction.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq4"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq4" className="accordion-header text-dark">
                    How do you ensure the quality and accuracy of AI models?
                  </label>
                  <div className="accordion-body">
                    We ensure the quality of AI models through rigorous data
                    validation, testing, and continuous improvement cycles. Our
                    process includes evaluating model performance with
                    real-world data, regular audits, and fine-tuning to improve
                    accuracy and reliability.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq5"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq5" className="accordion-header text-dark">
                    How long does it take to develop an AI solution?
                  </label>
                  <div className="accordion-body">
                    The timeline for AI software development varies depending on
                    the complexity of the project, the data requirements, and
                    the specific AI technologies being implemented. Typically,
                    an AI project can take anywhere from a few weeks to several
                    months. We provide clear milestones and timelines to ensure
                    timely delivery.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq6"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq6" className="accordion-header text-dark">
                    Can AI be integrated into my existing systems?
                  </label>
                  <div className="accordion-body">
                    Yes, we specialize in seamlessly integrating AI solutions
                    with your current infrastructure, including CRM systems,
                    databases, and third-party platforms. Our team ensures that
                    the AI tools align with your tech stack, enabling you to
                    maximize their potential without disrupting your existing
                    workflows.
                  </div>
                </div>
              </div>

              <div className="accordion mb-3">
                <div className="accordion-item">
                  <input
                    type="checkbox"
                    id="faq7"
                    className="accordion-toggle"
                  />
                  <label htmlFor="faq7" className="accordion-header text-dark">
                    Do you offer backend system maintenance and support?
                  </label>
                  <div className="accordion-body">
                    Yes, we provide ongoing maintenance and support for your
                    backend systems. This includes troubleshooting, performance
                    monitoring, regular updates, and implementing new features
                    as your business evolves.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BookNow />
    </>
  );
};

export default AiSoftwareDevelopemnt;
